<template>
  <footer class="full-width">
    <div class="grid-container container r-gap-15 md-r-gap-5">

      <div class="subscribe col xs-4 md-4-5 lg-6-7 flex r-gap-3">
        <h3><button-helper :type="'text'" :to="{ name: 'enquire' }" :class="'enquire-now-arrow-link'">Enquire now</button-helper></h3>
        <h3>Stay in the loop</h3>
        <form-helper :data="formData"></form-helper>
      </div>

      <div class="details col xs-4 md-4-1 lg-6-1 flex r-gap-3">
        <inline-svg :src="agIcon" class="icon" @click="scrollToTop"/>

        <ul class="flex r-gap-1">
          <li>
            <h5>Get in touch</h5>
          </li>
          <li>
            <a :href="`mailto: ${globalEmail}`">{{ globalEmail }}</a>
          </li>
          <li>
            Visit our sales office<br/>
            {{ globalAddress }}<br/>
            <a :href="globalLocation" target="_blank" rel="noopener noreferrer">Directions <span>↓</span></a>
          </li>
          <li v-if="globalInstagram || globalFacebook">
            <a v-if="globalInstagram" :href="globalInstagram" target="_blank" rel="noopener noreferrer">Instagram</a><br/>
            <a v-if="globalFacebook" :href="globalFacebook" target="_blank" rel="noopener noreferrer">Facebook</a>
          </li>
        </ul>
      </div>
      
      <div class="logo full">
        <inline-svg :src="agLogo" :class="'logo'"/>
        <router-link :to="{ name: 'legal'}" class="legal">Legal</router-link>
      </div>
    </div>

  </footer> 
</template>


<script>
  import { useRoute } from 'vue-router';
  import { computed, defineAsyncComponent, ref, reactive, watch } from 'vue';
  import { useApplicationStore } from '/stores/application';
  import { smoothScrollToTop } from '/helpers.js';
  import InlineSvg from '/components/helpers/InlineSvg.vue';
  import agIcon from '/assets/img/arbor-green-icon.svg?raw';
  import agLogo from '/assets/img/arbor-green-logo.svg?raw';

  export default {
    components: {
      InlineSvg,
      ButtonHelper: defineAsyncComponent(() => import("/components/helpers/ButtonHelper.vue")),
      FormHelper: defineAsyncComponent(() => import("/components/helpers/FormHelper.vue")),
    },
    props: {},
    setup(props) {
      const appStore = useApplicationStore();
      const route = useRoute();
      const buttonText = computed(() => appStore.themeType === 'light' ? 'light' : null);
      const buttonBg = computed(() => appStore.themeType === 'light' ? 'dark' : null);
      const globalEmail = computed(() => appStore.globalEmail);
      const globalAddress = computed(() => appStore.globalAddress);
      const globalInstagram = computed(() => appStore.globalInstagram);
      const globalFacebook = computed(() => appStore.globalFacebook);
      const globalLocation = computed(() => appStore.globalLocation);
      const formData = reactive({
        labels: false,
        showAsterisk: false,
        submitText: 'Submit',
        showSubmitOnSuccess: false,
        successMessage: 'Success.',
        successByline: 'Thanks – We will be in touch soon.',
        buttonTextColor: buttonText,
        buttonBgColor: buttonBg,
        formHandle: 'signUp',
        fields: [
          {
            name: 'emailAddress',
            label: 'Email',
            placeholder: 'Email address',
            type: 'email',
            autocomplete: 'email',
            value: '',
            required: true,
          },
          // {
          //   name: 'checkbox',
          //   label: 'I would like an agent to contact me',
          //   type: 'checkbox',
          //   value: false,
          //   required: false  
          // },
        ],
      })

      const scrollToTop = () => {
        smoothScrollToTop(document.documentElement)
      }

      const clickTest = () => {
        svgClass.value = 'bar';
      }

      const svgClass = ref('foo');

      watch(() => route.name, (newValue, oldValue) => {
        // Reset sticky values from any previous failed
        formData.fields[0].value = '';
      });

      return {
        buttonText,
        buttonBg,
        globalEmail,
        globalAddress,
        globalLocation,
        globalInstagram,
        globalFacebook,
        scrollToTop,
        formData,
        agIcon,
        agLogo,
        svgClass,
        clickTest,
        route
      };
    }
  }
</script>